<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="项目名称">
          <el-input
              size="small"
              clearable
              v-model="searchForm.projName"
              placeholder="请输入项目名称"
          ></el-input>
        </el-form-item>
        <!--        <el-form-item label="项目编号">-->
        <!--          <el-input-->
        <!--            size="small"-->
        <!--            v-model="searchForm.projCode"-->
        <!--            placeholder="请输入项目编号"-->
        <!--          ></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="所属机构">
          <el-cascader
              size="small"
              clearable
              v-model="orgaIdArr"
              :options="copanyListInfo"
              popper-class="custom-cascader"
              filterable
              :props="{
              checkStrictly: true,
              expandTrigger: 'hover',
              value: 'orgaPathIds',
            }"
              @change="cascaderChange"
          ></el-cascader>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
        :data="tableData"
        :total="total"
        noimport="true"
        isdel="true"
        :isadd="orgaLevel > 1"
        title="项目信息"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @tableExport="tableExport"
        @tableDel="tableDel"
        @tableAdd="tableAdd"
    >
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column
          prop="projName"
          label="项目名称"
          align="center"
          width="180">
      </el-table-column>
      <el-table-column
          prop="projAddress"
          align="center"
          label="项目地址">
      </el-table-column>
      <el-table-column
          prop="orgaPName"
          align="center"
          label="企业名称">
      </el-table-column>
      <el-table-column
          prop="projHead"
          align="center"
          label="联系人">
      </el-table-column>
      <el-table-column
          prop="phone"
          align="center"
          label="联系电话">
      </el-table-column>
      <el-table-column
          prop="projState"
          align="center"
          width="120"
          label="项目状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.projState == 0"> 正常</el-tag>
          <el-tag type="danger" v-if="scope.row.projState == 1"> 停工</el-tag>
          <el-tag type="info" v-if="scope.row.projState == 2"> 完结</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="createTime"
          align="center"
          label="创建时间">
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="orgaLevel <= 1" class="el-icon-edit btn-icon" @click="editItem(scope.row)"></span>
          <span v-if="orgaLevel <= 1" class="el-icon-delete btn-icon" @click="delItem(scope.row)"></span>
        </template>
      </el-table-column>
    </custom-tables>
    <edit-project
        :visible.sync="showDialog"
        :title="dialogTitle"
        :item="item"
        @close="showDialog = false"
        @done="editDone"
    ></edit-project>
  </div>
</template>

<script>
import basic from "../../../api/modules/basic";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import EditProject from "../../../components/page/basic/EditProject";
import {organizationCascader, downloadFile} from "../../../utils/tool";
export default {
  name: "project",
  components: {EditProject, SearchHead, CustomTables},
  data() {
    return {
      dialogTitle: "新增公司",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      item: {},
      copanyListInfo: [],
      orgaIdArr: [],
    };
  },
  computed: {
    // 控制左侧菜单栏折叠状态
    orgaLevel() {
      return this.$store.state.common.user.orgaLevel;
    },
  },
  mounted() {
    this.getCopanyInfo();
    this.getProjectInfo();
  },
  methods: {
    getCopanyInfo() {
      basic.getOrganizationTree({orgaPid: 0}).then((res) => {
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data, "noProject");
        this.copanyListInfo = data;
      });
    },
    getProjectInfo() {
      basic.getProjectListByPage(this.searchForm).then((res) => {
        this.tableData = res.data.list;
        console.log(this.tableData, ' this.tableData');
        this.total = res.data.total;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getProjectInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = pageSize;
      this.getProjectInfo();
    },
    headReset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.orgaIdArr = [];
      this.getProjectInfo();
    },
    headSearch() {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getProjectInfo();
    },
    tableDel() {
    },
    //导出
    tableExport() {
      let param = {
        currPage: this.searchForm.currPage,
        pageSize: this.searchForm.pageSize,
      };
      basic.getProjectRecExport(param).then((res) => {
        downloadFile(res)
      }).catch((err) => {
        console.log(err, "err");
      });
    },
    //格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    tableAdd() {
      this.item = {};
      this.dialogTitle = "新增项目";
      this.showDialog = true;
    },
    selectionChange(val) {
      console.log(val);
    },
    editItem(item) {
      this.item = {projHead:'',phone:'',...item,};
      this.dialogTitle = "编辑项目";
      this.showDialog = true;
    },
    delItem(item) {
      console.log(item, "item");

      let param = {
        projId: item.projId
      }
      this.$confirm(`此操作将永久删除该项目, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })

          .then(() => {
            basic.delProject(param).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getProjectInfo();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    editDone() {
      this.showDialog = false;
      this.getProjectInfo();
    },
    cascaderChange(val) {
      console.log(val);
      let valLen = val.length;
      this.searchForm.orgaPathIds = val[valLen - 1];
    },
  },
};
</script>

<style scoped></style>
