<template>
  <custom-dialog :visible.sync="visible" :title="title" @close="colseDialog" @submit="submit">
    <div class="edit-main">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form :model="editForm" label-width="80px" :rules="rules" ref="editProjectForm">
            <el-form-item label="项目名称" prop="projName">
              <el-input size="small" v-model="editForm.projName" placeholder="请输入项目名称"></el-input>
            </el-form-item>
            <el-form-item label="所属机构" prop="orgaIdArr">
              <el-cascader
                  size="small"
                  class="block-select"
                  v-model="editForm.orgaIdArr"
                  :options="copanyListInfo"
                  @change="cascaderChange"></el-cascader>
            </el-form-item>
            <el-form-item label="省" prop="areaPid">
              <el-select class="block-select" size="small" v-model="editForm.areaPid" placeholder="请选择所在省"
                         @change="changeAreaParent">
                <el-option v-for="item in areaParentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市" prop="areaId">
              <el-select class="block-select" size="small" v-model="editForm.areaId" placeholder="请选择所在市"
                         @change="changeArea">
                <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址" prop="projAddress">
              <el-input size="small" v-model="editForm.projAddress" placeholder="请输入公司地址"></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="projHead">
              <el-input size="small" v-model="editForm.projHead" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input size="small" v-model="editForm.phone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="项目状态" prop="projState">
              <el-select class="block-select" size="small" v-model="editForm.projState" placeholder="请选择项目状态">
                <el-option v-for="item in projectState" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <baidu-map :center="center"
                     :zoom="zoom" class="mapbox"
                     :scroll-wheel-zoom="true"
                     @ready="handler"
                     @click="getPoint">
            <bm-marker :position="{lng:editForm.projLong ,lat:editForm.projLot}"></bm-marker>
          </baidu-map>
        </el-col>
      </el-row>
    </div>
  </custom-dialog>
</template>

<script>
import basic from "../../../api/modules/basic";
import CustomDialog from "../../common/CustomDialog";
import {projectState} from "../../../config/dataStatus";
import {organizationCascader} from "../../../utils/tool";

export default {
  name: "EditProject",
  props:['visible','title','item'],
  components: {CustomDialog},
  watch:{
    item(newVal,oldVal){
      if(newVal.projId){
        // 编辑
        this.editForm = newVal
        // 组织机构树
        if(this.$store.state.common.user.orgaLevel > 0){
          this.editForm.orgaIdArr = [newVal.orgaPid]
        }else {
          this.editForm.orgaIdArr = [1,newVal.orgaPid]
        }
        if(newVal.areaPid){
          this.getAreaInfo(newVal.areaPid)
        }
      }else {
        this.editForm ={
          projName:'',
          orgaId:'',
          areaPid:'',
          areaId:'',
          projAddress:'',
          orgaIdArr:[],
          projState: 0
        }
      }
      this.resetForm()
    }
  },
  data(){
    return{
      center: {lng:116.404,lat:39.915},
      zoom: 15,
      editForm:{
        projName:'',
        orgaId:'',
        areaPid:'',
        areaId:'',
        projAddress:'',
        orgaIdArr:[],
      },
      rules: {
        projName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
        ],
        orgaIdArr: [
          { required: true, message: '请选择所属机构', trigger: 'change' }
        ],
        areaPid: [
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        projAddress: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
         projState: [
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
        ],
        projHead: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
      },
      areaParentList:[], // 省
      areaList: [], //市
      projectState:projectState,
      copanyListInfo:[]
    }
  },
  mounted() {
    this.getCopanyInfo()
    this.getAreaInfo(0)
  },
  methods:{
    getCopanyInfo(){
      basic.getOrganizationTree({orgaPid:0}).then(res =>{
        // 递归遍历 生成 {value, label, children}
        this.copanyListInfo = organizationCascader(res.data,'noProject')
      })
    },
    getAreaInfo(id){
      let param = {pid:id}
      basic.getAreaList(param).then(res =>{
        console.log(id,res)
        if(id ==0){
          this.areaParentList = res.data
        }else {
          this.areaList = res.data
        }
      })
    },
    changeAreaParent(id){
      console.log(id)
      this.editForm.areaId = ''
      this.getAreaInfo(id)
      // 地图的
      this.areaParentList.forEach(item =>{
        if(item.id === id){
          this.center = item.name
        }
      })
    },
    changeArea(id){
      // 市切换
      // 地图的
      console.log(id)
      this.areaList.forEach(item =>{
        if(item.id === id){
          if(item.name != this.center ){
            this.center = item.name
          }
        }
      })
      setTimeout(()=>{
        this.$forceUpdate()
      },800)
    },
    submit(){
      this.$refs.editProjectForm.validate((valid) => {
        if (valid) {
          if(this.editForm.projId){
            // 编辑
            basic.updateProject(this.editForm).then(res =>{
              this.$message({
                message: '编辑成功',
                type: 'success'
              });
              this.$emit('done')
            })
          }else {
            // 新增
            basic.addProject(this.editForm).then(res =>{
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.$emit('done')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    colseDialog(){
      this.$emit('close')
    },
    handler({ BMap, map }) {
      let mapStyle = {
        style: 'midnight'
      };
      map.setMapStyle(mapStyle);
    },
    getPoint(e) {
      //点击地图获取一些信息，
      // this.show = true;
      this.editForm.projLong = e.point.lng; //通过  e.point.lng获取经度
      this.editForm.projLot = e.point.lat; //通过  e.point.lat获取纬度
      let geocoder = new BMap.Geocoder(); //创建地址解析器的实例
      geocoder.getLocation(e.point, (res) => {
        this.editForm.projAddress = res.address;
        setTimeout(()=>{
          this.$forceUpdate()
        },800)
      });
    },
    cascaderChange(val){
      console.log(val)
      let valLen = val.length
      this.editForm.orgaPid = val[valLen-1]
    },
    resetForm(){
      if( this.$refs.editProjectForm){
        this.$refs.editProjectForm.clearValidate()
        this.$refs.editProjectForm.resetFields()
      }
    }

  }
}
</script>

<style scoped lang="less">
.edit-main{
  padding: 0 20px;
}
.mapbox{
  height: 300px;
  width: 100%;
}

</style>